import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { fluidRange } from 'polished';

import Grid from '../common/Grid';
import Waypoint from '../common/Waypoint';
import { H1 } from '../common/Typography';
import Section from '../common/Section';

import { fadeIn } from '../../utils/mixins';

const Wrapper = styled(Section)`
  ${fluidRange(
    {
      prop: 'padding-top',
      fromSize: '50px',
      toSize: '100px',
    },
    '380px',
    '1400px'
  )};
`;

const Main = styled.div`
  ${fadeIn()};
  text-align: center;
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: 3 / span 8;
  }
`;

const Camp = styled(Img)`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  ${fluidRange(
    {
      prop: 'margin-bottom',
      fromSize: '20px',
      toSize: '80px',
    },
    '380px',
    '1400px'
  )};
`;

export default function Hero() {
  return (
    <Waypoint
      render={({ visible }) => (
        <Wrapper>
          <Grid>
            <Main active={visible}>
              <StaticQuery
                query={graphql`
                  query {
                    camp: file(relativePath: { eq: "camp.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 390) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Camp
                    fluid={data.camp.childImageSharp.fluid}
                    role="presentation"
                    fadeIn={false}
                    placeholderStyle={{ display: 'none' }}
                  />
                )}
              />
              <H1>
                We’re an international collective of freelancers supporting
                altruistic progress through design and digital transformation.
              </H1>
            </Main>
          </Grid>
        </Wrapper>
      )}
    />
  );
}
