import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Waypoint from '../common/Waypoint';
import Grid from '../common/Grid';
import { H3, P } from '../common/Typography';
import Arrow from '../icons/Arrow';

import { verticalShuffle } from '../../utils/keyframes';
import { fadeIn } from '../../utils/mixins';

const Landscape = styled(Img)`
  ${fadeIn()};
  transition: opacity 1s ease, transform 1s ease;
  transform: ${({ active }) => active && `rotate(0deg)`};
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: span 6;
    width: 90%;
    box-shadow: -2em 2em 5em #9912025c;
  }
`;

const Wrapper = styled(Grid)`
  &:nth-child(even) ${Landscape} {
    transform: ${({ active, theme }) =>
      active
        ? `rotate(${theme.tilt}deg) scale(1)`
        : `rotate(-${theme.tilt}deg) scale(.9)`};
  }
  &:nth-child(odd) ${Landscape} {
    transform: ${({ active, theme }) =>
      active
        ? `rotate(-${theme.tilt}deg) scale(1)`
        : `rotate(${theme.tilt}deg) scale(.9)`};
  }
  @media (max-width: 999px) {
    grid-row-gap: 30px;
  }
`;

const Meta = styled.div`
  ${fadeIn()};
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: span 6;
  }
`;

const MoreAbout = styled(P)`
  display: block;
  display: flex;
  align-items: center;
`;

const MoreArrow = styled(Arrow)`
  margin-left: 6px;
  height: 16px;
  ${MoreAbout}:hover & {
    animation: ${verticalShuffle} 1s ease infinite;
  }
`;

export default function TeamMember({
  name,
  role,
  description,
  image,
  website,
}) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Wrapper active={visible}>
          <Landscape
            active={visible}
            fluid={image.localFile.childImageSharp.fluid}
          />
          <Meta active={visible}>
            <H3 bold>{name}</H3>
            <H3 margin as="p">
              {role}
            </H3>
            <P>{description}</P>
            <MoreAbout as="a" href={website} target="_blank">
              More about&nbsp;
              {name.split(' ')[0]}
              <MoreArrow />
            </MoreAbout>
          </Meta>
        </Wrapper>
      )}
    />
  );
}

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  website: PropTypes.string,
};
