import React from 'react';
import { fluidRange } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Waypoint from '../common/Waypoint';

import Grid from '../common/Grid';
import { H1 } from '../common/Typography';
import TeamMember from './TeamMember';
import Section from '../common/Section';

import { fadeIn } from '../../utils/mixins';

const Title = styled(H1)`
  grid-column: span 12;
  ${fadeIn()}
`;

const Members = styled.div`
  display: grid;
  ${fluidRange(
    {
      prop: 'grid-row-gap',
      fromSize: '40px',
      toSize: '240px',
    },
    '380px',
    '1400px'
  )};
`;

export default function OurTeam({ members }) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Section>
          <Grid>
            <Title active={visible}>Our Team</Title>
          </Grid>
          <Members>
            {members.map((member, i) => (
              <TeamMember
                key={i}
                name={member.member.document[0].data.name.text}
                role={member.member.document[0].data.role.text}
                image={member.member.document[0].data.image}
                description={member.member.document[0].data.description.text}
                website={member.member.document[0].data.website.url}
              />
            ))}
          </Members>
        </Section>
      )}
    />
  );
}

OurTeam.propTypes = {
  members: PropTypes.array.isRequired,
};
