import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Grid from '../common/Grid';
import Waypoint from '../common/Waypoint';
import { H2 } from '../common/Typography';
import Section from '../common/Section';
import UnstyledList from '../common/UnstyledList';
import CircularSpan from '../common/CircularSpan';

import { fadeIn } from '../../utils/mixins';

const Title = styled(H2)`
  ${fadeIn()}
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: span 6;
  }
`;

const List = styled(Grid)`
  grid-column-gap: 0;
  border-top: ${({ theme }) => `1px solid ${theme.colors.peach}`};
  ${fadeIn({ delay: 0.5 })}
`;

const Industry = styled.li`
  grid-column: span 12;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.peach}`};
  font-size: 20px;
  line-height: 50px;
  cursor: pointer;
  position: relative;
  @media (min-width: 600px) {
    font-size: 22px;
    line-height: 70px;
    grid-column: span 6;
  }
  @media (min-width: 1200px) {
    grid-column: span 4;
  }
`;

const IndustryName = styled.span`
  ${({ delay }) => fadeIn({ delay })};
`;

const Project = styled(Img)`
  position: absolute !important;
  top: -100%;
  width: 80%;
  opacity: 0;
  z-index: 10;
  transform: rotate(-10deg) scale(0);
  transform-origin: 20% 80%;
  transition: opacity 0.4s ease, transform 0.4s ease;
  pointer-events: none;
  box-shadow: -0.2em 0.2em 0.5em #9912021f;
  ${Industry}:hover & {
    opacity: 1;
    transform: rotate(-2deg);
  }
  @media (max-width: 599px) {
    width: 40%;
    right: 5%;
  }
  @media (min-width: 600px) {
    width: 60%;
    right: 5%;
  }
`;

export default function Industries({ industries }) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Section color="orange">
          <Grid>
            <Title active={visible}>
              We believe that strong process and direction work for any
              industry. These are&nbsp;
              <CircularSpan active={visible}>{industries.length}</CircularSpan>
              &nbsp;areas in which we’ve helped transform already:
            </Title>
          </Grid>
          <List active={visible} as={UnstyledList}>
            {industries.map((industry, i) => (
              <Industry key={i} as="li" active={visible}>
                <IndustryName active={visible} delay={i / 8}>
                  {industry.name.text}
                </IndustryName>
                <Project
                  fluid={industry.image.localFile.childImageSharp.fluid}
                  alt={industry.image.alt}
                />
              </Industry>
            ))}
          </List>
        </Section>
      )}
    />
  );
}

Industries.propTypes = {
  industries: PropTypes.array.isRequired,
};
