import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/common/Layout';
import SEO from '../components/common/Seo';
import Hero from '../components/about/Hero';
import Industries from '../components/about/Industries';
import OurTeam from '../components/about/OurTeam';
import PreFooter from '../components/common/PreFooter';

export default function About({ data }) {
  return (
    <Layout>
      <SEO title="About us" />
      <Hero />
      <Industries industries={data.prismicAbout.data.industries} />
      <OurTeam members={data.prismicAbout.data.team} />
      <PreFooter />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    prismicAbout {
      data {
        industries {
          image {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 275, maxHeight: 170, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          name {
            text
          }
        }
        team {
          member {
            document {
              data {
                name {
                  text
                }
                role {
                  text
                }
                description {
                  text
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(
                        maxWidth: 530
                        maxHeight: 330
                        cropFocus: CENTER
                        quality: 80
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                website {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

About.propTypes = {
  data: PropTypes.object.isRequired,
};
